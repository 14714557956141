/* eslint-disable react/display-name */
import useTranslation from 'next-translate/useTranslation';
import { memo, useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { StepProps } from 'src/containers/Quiz/models';
import { RoutePath } from 'src/constants/route-path';
import { HHero, H1, Text1Bold, Caption, A } from 'src/components/Text';
import ErrorNotification from 'src/components/ErrorNotification';

import { getCookie, setCookie } from 'src/utils/cookie';

import {
  StepWristGirthWrapper,
  StepWristGirthChoices,
  StepWristGirthChoicesItem,
  ChoiseImg,
  ChoiseBtn,
  PrivacyWrapper,
  PrivacyCheckbox,
  EmailConfirmWrapper,
  EmailConfirmCheckbox,
} from './step-wrist-girth';

export const StepWristGirth = memo(
  ({ data, onSubmit, showEmailCheckbox }: StepProps): JSX.Element => {
    const router = useRouter();
    const { t } = useTranslation('quiz');

    const [privacyChecked, togglePrivacy] = useState(true);
    const [emailChecked, toggleEmail] = useState(true);

    // const [isUtm, setIsUtm] = useState(false);
    // const [isPP, setIsPP] = useState(false);

    useEffect(() => {
      setCookie('wantReceiveMarketingEmails', emailChecked ? '1' : '0');
    }, [emailChecked]);

    useEffect(() => {
      if (Object.values(router.query).length <= 2) {
        toggleEmail(false);
        togglePrivacy(false);
      }
    }, []);

    return (
      <StepWristGirthWrapper>
        <HHero>{data.title}</HHero>
        <H1>{data.title}</H1>
        <Text1Bold>{data.subtitle}</Text1Bold>
        <StepWristGirthChoices>
          {data.nav.map((nav): JSX.Element[] =>
            data.selects[nav.id].options.map(
              (option): JSX.Element => (
                <StepWristGirthChoicesItem
                  key={option.id}
                  onClick={
                    privacyChecked
                      ? (): void =>
                          onSubmit({
                            formValue: { [option.id]: option.value },
                          })
                      : undefined
                  }
                >
                  <ChoiseImg>
                    <img src={option.icon} alt={option.title} loading="lazy" />
                  </ChoiseImg>
                  <ChoiseBtn disabled={!privacyChecked} type="button">
                    <Text1Bold>{option.title}</Text1Bold>
                    <Text1Bold>&gt;</Text1Bold>
                  </ChoiseBtn>
                </StepWristGirthChoicesItem>
              )
            )
          )}
        </StepWristGirthChoices>
        <PrivacyWrapper>
          <PrivacyCheckbox
            className={privacyChecked ? 'checked' : ''}
            role="button"
            onClick={(): void => {
              togglePrivacy(!privacyChecked);
            }}
          />
          <Caption>
            {t('goal.agreement')}{' '}
            <Link
              href={`${RoutePath.termsOfService}`}
              passHref
              prefetch={false}
            >
              <A>{t('goal.terms')}</A>
            </Link>
            ,{' '}
            <Link href={`${RoutePath.privacyPolicy}`} passHref prefetch={false}>
              <A>{t('goal.privacy')}</A>
            </Link>
            ,{' '}
            <Link
              href={`${RoutePath.subscriptionTerms}`}
              passHref
              prefetch={false}
            >
              <A>{t('goal.subscription')}</A>
            </Link>
            {', '}
            <Link href={`${RoutePath.cookiePolicy}`} passHref prefetch={false}>
              <A>{t('goal.cookiePolicy')}</A>
            </Link>
          </Caption>
        </PrivacyWrapper>
        {showEmailCheckbox && (
          <EmailConfirmWrapper>
            <EmailConfirmCheckbox
              className={emailChecked ? 'checked' : ''}
              role="button"
              onClick={(): void => {
                toggleEmail(!emailChecked);
              }}
            />
            <Caption>
              {t('goal.email')}
              {process.env.NEXT_PUBLIC_MIRROR_TITLE || 'Unimeal'}{' '}
              {t('goal.emailEnd')}
            </Caption>
          </EmailConfirmWrapper>
        )}
        {!privacyChecked && (
          <ErrorNotification>{t('goal.error')}</ErrorNotification>
        )}
      </StepWristGirthWrapper>
    );
  }
);

export default StepWristGirth;
