import { memo } from 'react';

import { Icon, IconTypes, IconSizes } from 'src/components/Icon';

import {
  ErrorNotificationContent,
  ErrorNotificationWrapper,
} from './error-notification-styled';

type Props = React.PropsWithChildren<{
  // eslint-disable-next-line react/require-default-props
  forwardedRef?: React.Ref<HTMLDivElement>;
}>;

function ErrorNotification({ children, forwardedRef }: Props): JSX.Element {
  return (
    <ErrorNotificationWrapper ref={forwardedRef}>
      <Icon type={IconTypes.cmnError} size={IconSizes.l} />
      <ErrorNotificationContent>{children}</ErrorNotificationContent>
    </ErrorNotificationWrapper>
  );
}

export default memo(ErrorNotification);
