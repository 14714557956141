import styled, { css } from 'styled-components';

import { Text2 } from 'src/components/Text';
import { mdMedia, smMedia, themeColor } from 'src/theme/utils';

export const ErrorNotificationWrapper = styled.div`
  display: flex;
  padding: 1rem;
  background: ${themeColor('red0')};
  margin: 0 -1.5rem;
  border-radius: 9px;
  ${smMedia(css`
    margin: 0 -3rem;
  `)}

  ${mdMedia(css`
    margin: 0 auto;
    max-width: 504px;
    width: 100%;
  `)}
`;

export const ErrorNotificationContent = styled(Text2)`
  flex: 1 1 auto;
  margin-left: 0.5rem;
`;
